<template lang="">
  <div class="text-center q-pa-md">
    <div class="text-body1">
      <div v-if="formDataValid">
        <form ref="redirectAuth" :action="sagepayFormData.formUrl" method="post">
          <input type="hidden" name="PaReq" :value="sagepayFormData.paReq">
          <input type="hidden" name="TermUrl" :value="sagepayFormData.termUrl">
          <input type="hidden" name="MD" :value="sagepayFormData.merchantData">
          <p>{{ $t('tooltip.bank_authorisation') }}</p>
          <q-btn label="continue" color="primary" type="submit" class="q-ma-md" />
        </form>
      </div>
      <div v-else>
        {{ $t('three_d_popup_should_close') }}
        <br>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      sagepayFormData: 'sagepay/formFields'
    }),
    isComplete () {
      return this.$route.query.complete
    },
    formDataValid () {
      var sp = this.sagepayFormData
      return sp.formUrl && sp.paReq && sp.termUrl && !this.isComplete
    }
  },
  mounted () {
    if (this.isComplete) {
      this.sendCompleteMessage()
    } else {
      if (!this.formDataValid) {
        this.$router.go(-1)
      }
      this.$nextTick(() => {
        this.$refs.redirectAuth.submit()
      })
    }
  },
  methods: {
    sendCompleteMessage () {
      window.parent.postMessage('transactionComplete', '*')
    }
  }
}
</script>
